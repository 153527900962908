import { Link } from "gatsby"
import React from "react"
import { Row } from "react-bootstrap"

const Term = props => {
  return (
    <Row className="termContainer">
      <div className="col-sm-12 col-md-10 col-lg-8">
        <div className="termAndPolicyContents">
          <p>この利用規約（以下「本規約」といいます。）には、株式会社ソノリテ（以下「当社」といいます。）が提供する本サービスの提供条件が定められています。本サービスの利用に際しては、本規約の全文をお読み頂いた上で、本規約に同意頂く必要があります。本規約の他、当社が別途サービス利用者又はユーザーに提示する本サービスの利用に関するルールは、本規約に組み込まれるものとします。但し、本規約の内容と、前記のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</p>
        
          <div className="termArticle">
            <h3>第1条（用語の定義）</h3>
            <p className="termSubParagraph">
              (1)　「本サービス」とは、Microsoft Teams上で動作する「ワカチエ」という名称のアプリ及びweb上の管理画面を意味します。
            </p>
            <p className="termSubParagraph">
              (2)　「サービス利用者」とは、本サービスを利用する法人又は団体を意味します。
            </p>
            <p className="termSubParagraph">
              (3)　「ユーザー」とは、サービス利用者が本サービスを使用させるその社員又は職員を意味します。
            </p>
            <p className="termSubParagraph">
              (4)　「サービス利用契約」とは、本規約の内容を契約条件に含む、当社とサービス利用者との間で締結される本サービスの利用契約を意味します。
            </p>
            <p className="termSubParagraph">
              (5)　「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
            </p>
          </div>
          <div className="termArticle">
            <h3>第2条（サービスの利用）</h3>
            <ol>
              <li>
                本サービスの利用を希望する法人又は団体は、本規約を遵守することに同意し、当社の定める一定の情報（以下「登録情報」といいます。）を所定の方法により当社に提供して、利用申込をすることができます。
              </li>
              <li>
                前項の利用申込が当社に到達したときに、当社とサービス利用者との間でサービス利用開始日を始期とする本サービス利用契約が成立します。
              </li>
              <li>
                サービス利用者は、契約期間中、その責任により、ユーザーに対し本サービスを使用させることができます。この場合、当社は、本サービスに関し、ユーザーの行為を本サービス利用者の行為とみなします。
              </li>
            </ol>
          </div>

          <div className="termArticle">
            <h3>第3条（サービス利用料金）</h3>
            <ol>
              <li>
                本サービスは、原則として有料サービスですが、初回トライアル利用期間（90日間）に限り無料サービスとして提供しています。
              </li>
              <li>
                サービス利用料金の金額、支払期日及び支払方法は、当社が、所定のページ上にて別途提示するとおりとします。
              </li>
              <li>
                サービス利用者は、サービス利用料金を、当社が指定し、サービス利用者が選択した支払方法により、当社に支払うものとします。
              </li>
              <li>
                サービス利用者が支払期日までにサービス利用料金を支払わなかった場合、当社は、サービス利用者に対し、年14.6%の割合による遅延損害金を請求することができるものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第4条（禁止事項）</h3>
            <p>サービス利用者及びユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。</p>
            <p className="termSubParagraph">
              (1)　法令に違反する行為又は犯罪に関連する行為
            </p>
            <p className="termSubParagraph">
              (2)　公序良俗に反する行為
            </p>
            <p className="termSubParagraph">
              (3)　当社、他のユーザー又は第三者の知的財産権、肖像権、プライバシーに関する権利、名誉、その他の権利又は利益を侵害する行為
            </p>
            <p className="termSubParagraph">
              (4)　本サービスの提供のために用いているネットワーク又はシステム等に過度な負荷をかける行為
            </p>
            <p className="termSubParagraph">
              (5)　サブスクリプションキーを第三者に利用させ又は漏洩する行為
            </p>
            <p className="termSubParagraph">
              (6)　本サービスの運営を妨害するおそれのある行為
            </p>
            <p className="termSubParagraph">
              (7)　前各号の他、当社が不適切と判断する行為
            </p>
          </div>
          
          <div className="termArticle">
            <h3>第5条（サービスの提供の中断）</h3>
            <ol>
              <li>
                以下の各号のいずれかに該当した場合、当社は、サービス利用者及びユーザーに事前に通知等することなく、本サービスの全部又は一部の提供を中断することができるものとします。
                <p className="termSubParagraph">
                  (1)　本サービスにかかるネットワーク又はシステム等の点検又は保守作業を行う場合
                </p>
                <p className="termSubParagraph">
                  (2)　本サービスにかかるネットワーク又はシステム等が停止した場合
                </p>
                <p className="termSubParagraph">
                  (3)　前各号の他、当社が本サービスの提供を中断する必要があると判断した場合
                </p>
              </li>
              <li>
                当社は、本条に基づき当社が行った行為によりサービス利用者又はユーザーに損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第6条（権利の帰属）</h3>
            <ol>
              <li>
                本サービスに関する知的財産権は、全て当社又は当社にライセンスを行っている第三者に帰属しています。
              </li>
              <li>
                当社は、明示的に意思表示をした場合を除き、サービス利用者又はユーザーに対し、本サービスに関する知的財産権を譲渡し、又は利用許諾するものではありません。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第7条（利用停止等）</h3>
            <ol>
              <li>
                当社は、サービス利用者又はユーザーが以下の各号のいずれかに該当した場合、事前にサービス利用者又はユーザーに通知することなく当該サービス利用者について本サービスの提供を一時的に停止し、又は本サービスの利用を恒常的に禁止することができるものとします。
                <p className="termSubParagraph">
                  (1)　サービス利用契約に違反した場合
                </p>
                <p className="termSubParagraph">
                  (2)　当社からの問い合わせに対して30日間以上応答がない場合
                </p>
                <p className="termSubParagraph">
                  (3)　反社会的勢力である、又は資金提供その他の方法で反社会的勢力の維持、運営もしくは経営に協力もしくは関与する等、反社会的勢力に何らかの関与がある場合
                </p>
                <p className="termSubParagraph">
                  (4)　過去、サービス利用契約その他の当社と締結した契約に違反した者又はその関係者である場合
                </p>
                <p className="termSubParagraph">
                  (5)　前各号の他、サービス利用契約を維持することが適当でないと当社が合理的な理由で判断した場合
                </p>
              </li>
              <li>
                当社は、本条に基づき当社が行った行為によりサービス利用者又はユーザーに損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第8条（サービス内容の変更・終了・精算）</h3>
            <ol>
              <li>
                当社は、当社の都合その他の事情により、本サービスの内容及び提供条件を変更し、又は本サービスの提供を終了することができます。
              </li>
              <li>
                当社は、本サービスの提供を終了する場合、サービス利用者に対して事前に通知します。また、当社の都合により本サービスの提供を終了する場合に限り、契約期間残期間分のサービス利用料金は、月割にて返金致します。
              </li>
              <li>
                本サービスの提供が終了した場合、同時にサービス提供契約も終了します。
              </li>
              <li>
                当社は、本条に基づき当社が行った行為によりサービス利用者又はユーザーに損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第9条（保証の否定及び免責）</h3>
            <ol>
              <li>
                当社は、以下の各号の事項について何ら保証するものではなく、当該事項を満たさなかったことに起因してサービス利用者又はユーザーに損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
                <p className="termSubParagraph">
                  (1)　本サービスがサービス利用者又はユーザーの特定の目的に適合すること
                </p>
                <p className="termSubParagraph">
                  (2)　本サービスが、サービス利用者又はユーザーの期待する機能・価値・正確性・有用性を有すること
                </p>
                <p className="termSubParagraph">
                  (3)　サービス利用者又はユーザーによる本サービスの利用が、サービス利用者又はユーザーに適用される法令又は業界団体の内部規則等に適合すること
                </p>
                <p className="termSubParagraph">
                  (4)　本サービスが中断なく利用できること
                </p>
                <p className="termSubParagraph">
                  (5)　本サービスに不具合が生じないこと
                </p>
              </li>
              <li>
                本サービスに関し、サービス利用者又はユーザーと他のサービス利用者、ユーザーその他の第三者との間でトラブルが生じた場合、当社は当該トラブルについて一切の責任を負わないものとします。
              </li>
              <li>
                当社が本サービスに関してサービス利用者又はユーザーに対して損害賠償責任を負う場合であっても、サービス利用者から受領したサービス利用料金のうち１ヶ月分に相当する金額を賠償金額総額の上限とし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、これを賠償する責任を負わないものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第10条（個人情報の取り扱い）</h3>
            <p>
              本サービスにおける当社の個人情報の取り扱いについては、当社が別途定めるプライバシーポリシー（URL：
              <Link to="/privacypolicy/" className="privacypolicyLink">https://wakachie.com/privacypolicy</Link>
              ）の定めによるものとし、サービス利用者及びユーザーは、このプライバシーポリシーに従って当社がサービス利用者又はユーザーに関する情報を取り扱うことについて同意するものとします。</p>
          </div>
          
          <div className="termArticle">
            <h3>第11条（契約期間）</h3>
            <ol>
              <li>
              無料サービスの契約期間は利用申込日から90日間とします。

              </li>
              <li>
                有料サービスの契約期間は利用開始日から1年間とし、契約満了日の1ヶ月前までにサービス利用者から何らの通知もない場合は、同一条件で1年間自動更新するものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第12条（規約の変更）</h3>
            <ol>
              <li>
                当社は、本規約の内容を合理的範囲内で変更することができるものとします。
              </li>
              <li>
                当社は、本規約を変更した場合、変更後の内容及び施行時期を自らが適当と判断した方法（所定のページ上での掲示を含みます。）によりサービス利用者及びユーザーに対して周知するものとします。
              </li>
              <li>
                当社が本規約の変更内容をサービス利用者及びユーザーに通知した後にサービス利用者もしくはいずれかのユーザーが本サービスを利用した場合、サービス利用者及びユーザーは本規約の変更に同意したものとみなします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第13条（連絡・通知の方法）</h3>
            <ol>
              <li>
                本サービスに関する問い合わせその他のサービス利用者から当社に対する連絡又は通知は、当社が別途指定する方法で行わなければならないものとします。
              </li>
              <li>
                当社がサービス利用者又はユーザーに対して連絡又は通知をする場合は、登録情報に登録されたサービス利用者の連絡先に対して行うことにより連絡又は通知がなされたものとします。サービス利用者が登録情報の変更を怠った場合にサービス利用者又はユーザーに生じた損害について当社は一切の責任を負わないものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第14条（契約上の地位の譲渡等）</h3>
            <ol>
              <li>
              サービス利用者又はユーザーは、当社の事前の承諾なくサービス利用契約上の地位又はサービス利用契約に基づく権利もしくは義務について、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。
              </li>
              <li>
                当社は、本サービスにかかる事業を事業譲渡及び会社分割その他の方法（以下「事業譲渡等」といいます。）を用いて第三者に譲渡する場合には、当該事業譲渡等に伴ってサービス利用契約上の地位、サービス利用契約に基づく権利及び義務ならびにサービス利用者又はユーザーに関する情報を当該事業譲渡等の譲受人に譲渡及び移転することができるものとし、サービス利用者及びユーザーは、かかる譲渡及び移転についてあらかじめ同意したものとします。
              </li>
            </ol>
          </div>
          
          <div className="termArticle">
            <h3>第15条（分離可能性）</h3>
            <p>本規約のいずれかの条項又はその一部が消費者契約法その他の法令等によって無効又は執行不能と判断された場合であっても、本規約の残りの規定、及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して効力を有するものとします。</p>
          </div>
          
          <div className="termArticle">
            <h3>第16条（準拠法）</h3>
            <p>本規約及びサービス利用契約の準拠法は日本法とします。</p>
          </div>

          <div className="termArticle">
            <h3>第17条（管轄）</h3>
            <p>本規約又はサービス利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所を第一審専属管轄裁判所とします。</p>
          </div>
        </div>

      </div>
      
    </Row>
  )
}

export default Term
